import React, { FC } from 'react'
import { Grid, Typography, Button, Container, Box } from '@mui/material'
import { Link } from 'gatsby'
import detektalogo from './assets/images/detektalogo.svg';
import herodetekta from './assets/images/heroDetekta.png';
import detektaMobImg from './assets/images/detektaHeroMobile.png'
import './styles.scss'

const DetektaHero: FC = () => {
  return (
    <>
      <Container sx={{mt:{xs:"none"}, pt:{xs:"none"}}}>
        <Grid container spacing={{md:4, xs:'none'}} mt={{md:"1rem", xs:"none"}} p={{md:"2.5rem", xs:'1.5rem'}}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', mt:{xs:'2rem' ,md:'0'} }}>
            <Typography color="text.white" gutterBottom variant="h2" className="hero-title-detekta" sx={{my:{ md:'1.5rem',sm: 'none', xs: 'none'}}}>
              <strong>Haz más eficiente</strong> tu negocio obteniendo un análisis automático del <strong> Certificado de Tradición y Libertad.</strong>
            </Typography>
            <img src={detektalogo} alt="" width={"250px"}/>
            <Link to='/contacto'>
              <Button variant="outlined" sx={{ my: { md: 3, sm: 1 } }} color="primary" className="ing-index-hero__button">Contáctanos</Button>
            </Link>
          </Grid>
          <Grid sx={{ display: { xs: 'none', md: 'block' }, height:'550px' }} item xs={12} md={6} >
            <img src={herodetekta} alt="intersect img" className="ing-index-hero__img" />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default DetektaHero